import { Trans, useTranslation } from 'next-i18next';
import { useMemo } from 'react';

import { FormattedPrice } from '@/core/ui/components/FormattedPrice/FormattedPrice';
import { PromotionBadge } from '@/core/ui/components/PromotionBadge/PromotionBadge';
import { cn } from '@/core/ui/utils';
import { Product } from '@/modules/product/queries/types';
import { checkPriceDifference } from '@/modules/product/services/checkPriceDifference';

export const Omnibus = ({
  price,
  isPromoted,
}: {
  price: Product['price'];
  isPromoted: boolean;
}) => {
  const { t } = useTranslation('product');

  const pricePercentageDifference = useMemo(
    () => checkPriceDifference(price.discounted?.discount?.value),
    [price.discounted?.discount?.value]
  );

  return (
    <div className={cn('md:flex', 'md:gap-x-2', 'md:items-center', 'md:mt-1')}>
      {price?.discounted && (
        <div className="my-1 mb-2 md:m-0">
          <p
            className={cn(
              'text-xs',
              isPromoted ? 'text-text-subtle' : 'text-gray-500'
            )}
          >
            {t('lowest price')}
          </p>
        </div>
      )}
      <div className="md:-order-1">
        {pricePercentageDifference.discountType ? (
          <PromotionBadge variant={isPromoted ? 'promoted' : 'default'}>
            {pricePercentageDifference.discountType === 'PERCENTAGE' ? (
              <Trans
                ns="product"
                i18nKey="-{{value}}%"
                values={{
                  value: Math.ceil(pricePercentageDifference.difference),
                }}
              />
            ) : (
              <FormattedPrice
                hideDecimals
                centAmount={pricePercentageDifference.difference}
                currencyCode={pricePercentageDifference.currencyCode}
              />
            )}
          </PromotionBadge>
        ) : null}
      </div>
    </div>
  );
};
